import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Moment from "react-moment"

const ArticleCard = ({ article }) => {
  // console.log("Article Card: ", article)
  return (
    <div className="pooche-card" style={{ padding: "10px" }}>
      <div
        className="uk-card uk-card-default uk-card-small uk-border-rounded"
        style={{ height: "100%" }}
      >
        <div className="uk-card-media-top">
          <Link to={`/article/${article.slug}`}>
            {article?.cover && (
              <GatsbyImage
                image={getImage(article.cover.localFile)}
                alt={article.headings.title}
                title={article.headings.title}
                data-uk-img="target: !.uk-slideshow-items"
              />
            )}
          </Link>
        </div>
        <div className="uk-card-header">
          <div className="uk-grid-small uk-flex-middle" data-uk-grid="true">
            <div className="uk-width-auto">
              {article?.author?.avatar && (
                <GatsbyImage
                  image={getImage(article.author.avatar.localFile)}
                  alt={article.headings.title}
                  title={article.headings.title}
                  className="uk-border-circle"
                  style={{ width: "50px", height: "50px" }}
                />
              )}
            </div>
            <div className="uk-width-expand">
              <span>
                <h6
                  className="uk-margin-remove-bottom uk-text-bold"
                  style={{ color: "#617F7F" }}
                >
                  {article.author.name}
                </h6>
              </span>
              <p className="uk-text-meta uk-margin-remove-top uk-text-small">
                <Moment format="MMMM Do YYYY">{article.publishedAt}</Moment>
              </p>
            </div>
          </div>
        </div>
        <div className="uk-card-body">
          <h4 className="uk-margin-small-bottom uk-text-bold green-grad">
            {article.cardTitle}
          </h4>
          <p className="uk-text-small">{article.shortDesc}</p>
          <Link
            className="hover-underline-animation"
            style={{ color: "#617F7F", textDecoration: "none" }}
            to={`/article/${article.slug}`}
          >
            READ MORE
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ArticleCard
