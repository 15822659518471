import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Hero from "../components/hero"
import FeaturedArticle from "../components/featured-article"
import SliderSwitcherContent from "../components/slider-switcher-content"
import ArticleSlider from "../components/article-slider"
import CtaSlider from "../components/cta-slider"
import ReactLightbox from "../components/react-lightbox"

const SponsorshipPage = () => {

  const {
    strapiSponsorship,
    allStrapiSliderSwitcherContentSection,
    allStrapiArticle,
    allStrapiGallerySection,
  } = useStaticQuery(graphql`
    query {
      strapiSponsorship {
        hero {
          ...StrapiHero
        }
        headings {
          title
          description
        }
        featuredArticle {
          ...StrapiFeaturedArticle
        }
        callToAction {
          ...StrapiCallToAction
        } 
        seo {
          ...StrapiSeo
        }
      }
      allStrapiSliderSwitcherContentSection(
        filter: { location: { eq: "sponsorship-page" }, featured: { eq: true } }
        sort: { order: ASC, fields: sequence }
      ) {
        ...SliderSwitcherContentSections
      }
      allStrapiArticle(
        filter: {
          category: { name: { eq: "Sponsorship" } }
          location: { eq: "sponsorship-page" }
          featured: { eq: true }
        }
        sort: { order: DESC, fields: updatedAt }
      ) {
        nodes {
          ...StrapiArticles
        }
      }
      allStrapiGallerySection(
        filter: { location: { eq: "sponsorship-page" } }
      ) {
        nodes {
          ...StrapiGallerySection
        }
      }
    }
  `)

  const {
    hero,
    seo,
    headings,
    featuredArticle,
    callToAction,
  } = strapiSponsorship

  const articles = allStrapiArticle.nodes

  const sliderSwitcherContent = allStrapiSliderSwitcherContentSection.nodes

  const gallerySection = allStrapiGallerySection.nodes[0]

  const callToActionSections = callToAction.call_to_action_sections

  const structuredData = seo.structuredData

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage.localFile.publicURL}
        post={false}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />

      <Hero hero={hero} />

      <FeaturedArticle 
        featuredArticle={featuredArticle}
        title={headings.title} 
        description={headings.description}
        bg="uk-background-default"
      />

      <SliderSwitcherContent
        sliderSwitcherContent={sliderSwitcherContent}
        title="Sponsor a street dog today!"
        description="Please see some of the latest successful rescues, and some of the dogs in our care, that are in most need of your continued kindness and support"
        bg="uk-background-muted"
      />

      <ReactLightbox
        images={gallerySection.images}
        location={gallerySection.location}
        title={gallerySection.headings.title}
        description={gallerySection.headings.description}
        bg="uk-background-default"
        //remainingImages={remainingImages}
      />

      <ArticleSlider 
        articles={articles} 
        title="Featured Articles"
        description="Checkout some of our stories from STSP LTD"
        bg="uk-background-muted"
      />

      <CtaSlider 
        title={callToAction.title} 
        description={callToAction.description} 
        callToActionSections={callToActionSections} 
        bg="uk-background-default"
      />
    </>
  )
}

export default SponsorshipPage
